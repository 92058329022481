import React from 'react';
import { FiCheck } from "react-icons/fi";
import { Link } from 'gatsby';
import {StaticImage} from "gatsby-plugin-image";

function TransportGoodToKnow(props) {
    return (
        <div className="rn-about-area ptb--120 bg_color--3">
            <div className="container">
                <div className="row row--35 align-items-center">
                    <div className="col-lg-6 order-2 order-lg-1">
                        <div className="about-inner inner">
                            <div className="section-title">
                                <h2 className="title">It is good to know</h2>
                            </div>
                            <div className="mt--30">
                                <ul className="list-style--1">
                                    <li>
                                        <FiCheck />
                                        Viewing is possible only with prior notice  - <Link to="/en/contact-and-information">reservation.</Link>
                                    </li>

                                    <li>
                                        <FiCheck />
                                        The visit is suitable for everyone, even the disabled, because the tunnel has no slope.
                                    </li>

                                    <li>
                                        <FiCheck />
                                        The visitor receives protective equipment – a helmet, we recommend comfortable footwear and clothing.
                                    </li>

                                    <li>
                                        <FiCheck />
                                        The smallest group for viewing is 4 people.
                                    </li>

                                    <li>
                                        <FiCheck />
                                        If the visitor wishes the mine <Link to={"/en/visit-the-mine/culinary"}>culinary cuisine – Sitarjevec snack </Link> can be served in the pit.
                                    </li>

                                    <li>
                                        <FiCheck />
                                        The tour of the mine lasts up to an hour.
                                    </li>

                                    <li>
                                        <FiCheck />
                                        The pit has a constant temperature of 10 °C and 100 % humidity.
                                    </li>
                                </ul>
                            </div>

                            <div className="mt--40">
                                <Link to="/en/contact-and-information" className="btn-default size-md">Book a Tour</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 order-1 order-lg-2">
                        <div className="thumbnail">
                            <StaticImage
                                src="../../../assets/images/rudnik/izvozni-rov-rudnika-v-sitarjevcu-2.jpg"
                                alt="Stalactite in the Sitarjevec mine"
                                placeholder="blurred"
                                imgClassName="img-fluid w-100"
                                loading="lazy"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default TransportGoodToKnow;