import React from 'react';
import {StaticImage} from "gatsby-plugin-image";

function TransportRowDescription(props) {
    return (
        <div>
            <div className="about-wrapper ptb--120 bg_color--1">
                <div className="container">
                    <div className="row align-items-center text-center text-lg-left">
                        <div className="col-lg-5 col-md-12 col-xl-5 order-1 mt-4 order-lg-0">
                            <div className="thumbnail">
                                <StaticImage
                                    src="../../../assets/images/rudnik/vhod-v-rudnik-sitarjevec.jpg"
                                    alt="Entrance to the Sitarjevec Mine"
                                    placeholder="blurred"
                                    imgClassName="img-fluid w-100"
                                    loading="lazy"
                                />
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-12 col-xl-7 order-0 order-lg-1">
                            <div className="about-inner inner p-2 p-lg-4">
                                <div className="section-title">
                                    <h2 className="title">Guided Tours <br /> of the Export Pit</h2>
                                    <p className="description">
                                        Sitarjevec Litija mine is a modern underground museum that offers visitors an insight into the diversity of geological and natural heritage, the world of bats, spiders and fungi, as well as the richness of the mining tradition. experience all this already in the Export pit, which is easily accessible to almost all groups of visitors.
                                    </p>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="about-wrapper pb--120">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-9 col-xl-10 col-12 text-center text-lg-left">
                            <div className="about-inner inner">
                                <div className="section-title">
                                    <h2 className="title">The magical world of Litija’s underworld</h2>
                                    <p className="description">
                                        The museum's layout is based on the spatial features of the underground architecture, with an emphasis on the details of the stylized cinnabar crystal, which runs like a red thread from the information pillar placed in front of the entrance to the interior of the mine. The visitor experiences its surprising colorfulness, can be in contact with the environment of the formation of rocks and fossils, jewelry and the use of colors made from them.
                                    </p>
                                    <p className="description">
                                        As a tourist is being led through the Export pit the stories of the minerals intertwine with the stories of the miners. You will learn about the method of mining in the last period of the mine operation. You will see a collection of mining tools, hear some mining anecdotes and a legend about St. Barbara, the patron saint of miners. Then they will continue their research by visiting an almost vertical excavation field with an undiscovered barite vein, the reconstruction of a wooden support, rails with a mining cart and a rich collection of rocks and ores from Sitarjevec. Visitors will watch a short film about the mine in the mine hall also.
                                    </p>
                                    <p className="description">
                                        The intertwining of natural sights and artistic creation culminates in the reconstruction and interpretation of the paleo environment and the importance of light in the Sitarjevec mine. The artist expressed the later with a light performance of unusual lights, fireflies, which were hung under the ceiling of the mine shaft.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TransportRowDescription;