import React from 'react';
import {graphql} from "gatsby";

import SEO from "../../../components/en/seo/Seo";
import Header from "../../../components/en/headers/Header";
import Footer from "../../../components/en/layouts/Footer";
import CallToAction from "../../../components/en/layouts/CallToAction";
import StaticMainRowGallery from "../../../components/en/main-row/StaticMainRowGallery";
import TransportRowHeader from "../../../components/en/transport-row/TransportRowHeader";
import TransportGoodToKnow from "../../../components/en/transport-row/TransportGoodToKnow";
import TransportRowDescription from "../../../components/en/transport-row/TransportRowDescription";

function TransportRow({data}) {
    return (
        <>
            <SEO
                title="The Export Pit"
                description="Sitarjevec Litija mine is a modern underground museum that offers visitors an insight into the diversity of geological and natural heritage, the world of bats, spiders and fungi, as well as the richness of the mining tradition. experience all this already in the Export pit, which is easily accessible to almost all groups of visitors."/>
            <Header/>
            <TransportRowHeader/>
            <TransportRowDescription/>
            <TransportGoodToKnow/>
            <StaticMainRowGallery imagesData={data.source.edges}/>
            <CallToAction/>
            <Footer />
        </>
    );
}

export const pageQuery = graphql`
    query ImagesForTransportRowGalleryInEnglish {
        source: allFile(filter: {relativePath: {regex: "rudnik/izvozni-rov-galerija/"}}) {
            edges {
                node {
                    childImageSharp {
                        thumb: gatsbyImageData(
                            width: 200
                            height: 200
                            placeholder: BLURRED
                        )
                        full: gatsbyImageData(layout: FULL_WIDTH)
                    }
                }
            }
        }
    }
`;

export default TransportRow;